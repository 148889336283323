import React, { Component } from "react";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { BrowserRouter as Router, Route } from "react-router-dom";
import logo from "./space_x.png";
import Launches from "./components/Launches";
import Launch from "./components/Launch";

const client = new ApolloClient({
  uri: "/graphql"
});

class App extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <Router>
          <div className="container">
            <h1>
              <img
                src={logo}
                alt="SpaceX"
                style={{ width: 300, display: "block", margin: "auto" }}
              />
            </h1>
            <Route exact path="/" component={Launches} />
            <Route exact path="/launch/:id" component={Launch} />
          </div>
        </Router>
      </ApolloProvider>
    );
  }
}

export default App;
